@font-face {
  font-family: s50icons;
  src: url(https://d3hvyery48t45w.cloudfront.net/2021-06-01-a/static/media/s50icons.0b3d83c3.ttf) format("truetype"),url(https://d3hvyery48t45w.cloudfront.net/2021-06-01-a/static/media/s50icons.13ffca26.woff) format("woff"),url(https://d3hvyery48t45w.cloudfront.net/2021-06-01-a/static/media/s50icons.9bf150d7.svg#s50icons) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: block
}

[class*=" s50-icon-"],[class^=s50-icon-] {
  font-family: s50icons!important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.s50-icon-liv_chevron-up:before {
  content: ""
}

.s50-icon-liv_gamesheet:before {
  content: ""
}

.s50-icon-liv_clock:before {
  content: ""
}

.s50-icon-liv_check-alt:before {
  content: ""
}

.s50-icon-liv_check:before {
  content: ""
}

.s50-icon-liv_camera:before {
  content: ""
}

.s50-icon-liv_camcoder:before {
  content: ""
}

.s50-icon-liv_calendar:before {
  content: ""
}

.s50-icon-liv_brush:before {
  content: ""
}

.s50-icon-liv_box:before {
  content: ""
}

.s50-icon-liv_adjust:before {
  content: ""
}

.s50-icon-liv_bar-chart:before {
  content: ""
}

.s50-icon-liv_users:before {
  content: ""
}

.s50-icon-liv_user:before {
  content: ""
}

.s50-icon-liv_trophy:before {
  content: ""
}

.s50-icon-liv_timer:before {
  content: ""
}

.s50-icon-liv_smartphone:before {
  content: ""
}

.s50-icon-liv_share:before {
  content: ""
}

.s50-icon-liv_settings:before {
  content: ""
}

.s50-icon-liv_priority-high:before {
  content: ""
}

.s50-icon-liv_piggybank:before {
  content: ""
}

.s50-icon-liv_pie-chart:before {
  content: ""
}

.s50-icon-liv_pen:before {
  content: ""
}

.s50-icon-liv_morph-heart:before {
  content: ""
}

.s50-icon-liv_lock:before {
  content: ""
}

.s50-icon-liv_image:before {
  content: ""
}

.s50-icon-liv_home:before {
  content: ""
}

.s50-icon-liv_grid:before {
  content: ""
}

.s50-icon-liv_globe:before {
  content: ""
}

.s50-icon-liv_flag:before {
  content: ""
}

.s50-icon-liv_dropper:before {
  content: ""
}

.s50-icon-liv_diagram:before {
  content: ""
}

.s50-icon-liv_desktop:before {
  content: ""
}

.s50-icon-liv_compass:before {
  content: ""
}

.s50-icon-liv_comments:before {
  content: ""
}

.s50-icon-liv_coins:before {
  content: ""
}

.s50-icon-competitive_team:before {
  content: ""
}

.s50-icon-liv_envelope-pull:before {
  content: ""
}